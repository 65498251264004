import React, { FC, ReactNode } from 'react'
import { Styled } from './styled'

type TTypographyProps = {
  children?: ReactNode
  as?: React.ElementType
  className?: string
}

export const Typography: FC<TTypographyProps> = ({
  as,
  children,
  className
}) => {
  if (as === 'h2') {
    return <Styled.TitleH2>{children}</Styled.TitleH2>
  }
  if (as === 'h3') {
    return <Styled.TitleH3>{children}</Styled.TitleH3>
  }
  return (
    <Styled.Title as={as} className={className}>
      {children}
    </Styled.Title>
  )
}
