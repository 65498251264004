import React, { FC } from 'react'
import { Styled } from './styled'

export const Footer: FC = () => {
  return (
    <Styled.Footer>
      <Styled.Text as='p'>
        «Аз софтваре» <span>/</span> ОГРН 1167847127612 <span>/</span> ИНН
        7842096144
      </Styled.Text>
    </Styled.Footer>
  )
}
