import React, { CSSProperties, DetailedHTMLProps, HTMLAttributes } from 'react'
import { createAnimation, cssValue } from './util'
import { Styled } from './styled'

export type LengthType = number | string

interface CommonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color?: string
  loading?: boolean
  cssOverride?: CSSProperties
  speedMultiplier?: number
}

export interface LoaderSizeProps extends CommonProps {
  size?: LengthType
}

const clip = createAnimation(
  'ClipLoader',
  '0% {transform: rotate(0deg) scale(1)} 50% {transform: rotate(180deg) scale(0.8)} 100% {transform: rotate(360deg) scale(1)}',
  'clip'
)

export const ClipLoader = ({
  loading = true,
  color = '#000000',
  speedMultiplier = 1,
  cssOverride = {},
  size = 35,
  ...additionalprops
}: LoaderSizeProps): JSX.Element | null => {
  const style: React.CSSProperties = {
    background: 'transparent !important',
    width: cssValue(size),
    height: cssValue(size),
    borderRadius: '100%',
    border: '2px solid',
    borderTopColor: color,
    borderBottomColor: 'transparent',
    borderLeftColor: color,
    borderRightColor: color,
    display: 'inline-block',
    animation: `${clip} ${0.75 / speedMultiplier}s 0s infinite linear`,
    animationFillMode: 'both',
    ...cssOverride
  }

  if (!loading) {
    return null
  }

  return (
    <Styled.Wrapper>
      <span style={style} {...additionalprops} />
    </Styled.Wrapper>
  )
}
