import React, { FC, ReactNode } from 'react'
import { Styled } from './styled'
import { Link } from 'react-router-dom'

type TLogoProps = {
  children?: ReactNode
}

export const Logo: FC<TLogoProps> = () => {
  return (
    <Styled.Logo>
      <Link to='/'>
        <span>Az</span> software
      </Link>
    </Styled.Logo>
  )
}
