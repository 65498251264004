import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${normalize}
  
  *, html, body {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
  }

  html, body {
    background-color: ${props => props.theme.color.gray};
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
  }
  
  #root {
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    padding: 38px 25px 15px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (min-width: 1024px) {
      width: 95%;
      padding: 38px 0 40px;
    }

    @media screen and (min-width: 1280px) {
      margin: 0 auto;
      //max-width: 1824px;
    }
  }
`

export default GlobalStyle
