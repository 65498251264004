import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  MouseEvent
} from 'react'
import { Styled } from './styled'
import { useWindowSize } from '../../hooks/useWindowSize'
import { ReactComponent as MenuOpen } from '../../assets/menu.svg'
import { ReactComponent as MenuClose } from '../../assets/close.svg'
import { useMatch } from 'react-router-dom'

type TMenuProps = {
  children?: ReactNode
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const Menu: FC<TMenuProps> = ({ open, setOpen }) => {
  const { width } = useWindowSize()
  const matchCapabilities = useMatch('/capabilities')
  const matchProjects = useMatch('/projects')
  const matchContacts = useMatch('/contacts')

  const handleClickMenuIcon = () => {
    setOpen(prevState => !prevState)
  }

  const handleClickMenuItem = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault()
    if (width && width < 1024) {
      setOpen(prevState => !prevState)
    }
  }

  return (
    <>
      <Styled.MenuIconWrapper>
        {open ? (
          <MenuClose onClick={handleClickMenuIcon} />
        ) : (
          <MenuOpen onClick={handleClickMenuIcon} />
        )}
      </Styled.MenuIconWrapper>
      <Styled.Menu open={open}>
        <Styled.MenuItem onClick={handleClickMenuItem}>
          <span>/</span>
          <Styled.Link to='/'>Главная</Styled.Link>
        </Styled.MenuItem>
        <Styled.MenuItem onClick={handleClickMenuItem}>
          <span>/</span>
          <Styled.Link active={!!matchCapabilities} to='capabilities'>
            Наши возможности
          </Styled.Link>
        </Styled.MenuItem>
        <Styled.MenuItem onClick={handleClickMenuItem}>
          <span>/</span>
          <Styled.Link active={!!matchProjects} to='projects'>
            Проекты
          </Styled.Link>
        </Styled.MenuItem>
        <Styled.MenuItem onClick={handleClickMenuItem}>
          <span>/</span>
          <Styled.Link active={!!matchContacts} to='contacts'>
            Контакты
          </Styled.Link>
        </Styled.MenuItem>
      </Styled.Menu>
    </>
  )
}
