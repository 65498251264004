import styled from 'styled-components'
import { Typography } from '../Typography/Typography'

const Footer = styled.footer`
  min-height: 24px;
  max-height: 48px;
`

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #000;

  @media screen and (min-width: 1024px) {
    text-align: right;
  }

  span {
    color: ${props => props.theme.color.blue};
  }
`

export const Styled = { Text, Footer }
