import React, { FC, ReactNode, useState } from 'react'
import { Logo } from '../Logo/Logo'
import { Menu } from '../Menu/Menu'
import { Styled } from './styled'

type THeaderProps = {
  children?: ReactNode
}

export const Header: FC<THeaderProps> = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Styled.Header open={open}>
        <Logo />
        <Menu open={open} setOpen={setOpen} />
      </Styled.Header>
    </>
  )
}
