import React, { FC, ReactNode } from 'react'
import { Styled } from './styled'

type TContainerProps = {
  children?: ReactNode
}

export const Container: FC<TContainerProps> = ({ children }) => {
  return <Styled.Container>{children}</Styled.Container>
}
