export const theme = {
  color: {
    black: '#28262E',
    red: '#FF0000',
    lightRed: 'rgba(255, 0, 0, 0.5)',
    blue: '#0028FF',
    gray: '#F7F9FB',
    darkGray: '#E1EBF2',
    white: '#FFFFFF'
  }
}
