import styled from 'styled-components'

const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0;
  margin: 0;
  color: ${({ theme }) => theme.color.black};

  @media screen and (min-width: 1024px) {
    font-size: 56px;
    line-height: 80px;
  }
`

const TitleH2 = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
`

const TitleH3 = styled.h3`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
`

export const Styled = { Title, TitleH2, TitleH3 }
