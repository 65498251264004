import styled from 'styled-components'

const Container = styled.main`
  display: flex;
  flex: 1 0 auto;
  @media screen and (min-width: 1024px) {
  }
`

export const Styled = { Container }
