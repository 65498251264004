import React, { Suspense, lazy } from 'react'
import { ThemeProvider } from 'styled-components'
import { Route, Routes } from 'react-router-dom'
import GlobalStyle from './GlobalStyle'
import { theme } from './theme'
import { Header } from './components/Header/Header'
import { Container } from './components/Container/Container'
import { Footer } from './components/Footer/Footer'
import { ClipLoader } from './components/ClipLoader/ClipLoader'
const Main = lazy(() => import('./pages/Main/Main'))
const Contacts = lazy(() => import('./pages/Contacts/Contacts'))
const Capabilities = lazy(() => import('./pages/Capabilities/Capabilities'))
const Projects = lazy(() => import('./pages/Projects/Projects'))

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <Container>
          <Routes>
            <Route
              path='/'
              element={
                <Suspense
                  fallback={<ClipLoader size={100} color={theme.color.blue} />}
                >
                  <Main />
                </Suspense>
              }
            />
            <Route
              path='contacts'
              element={
                <Suspense
                  fallback={<ClipLoader size={100} color={theme.color.blue} />}
                >
                  <Contacts />
                </Suspense>
              }
            />
            <Route
              path='capabilities'
              element={
                <Suspense
                  fallback={<ClipLoader size={100} color={theme.color.blue} />}
                >
                  <Capabilities />
                </Suspense>
              }
            />
            <Route
              path='projects'
              element={
                <Suspense
                  fallback={<ClipLoader size={100} color={theme.color.blue} />}
                >
                  <Projects />
                </Suspense>
              }
            />
          </Routes>
        </Container>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default App
