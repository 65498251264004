import styled from 'styled-components'
import { NavLink, NavLinkProps } from 'react-router-dom'

const Menu = styled.ul<{ open: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 94px 51px 337px;
  gap: 24px;
  background: ${props => props.theme.color.white};
  border-radius: 34px;
  transform: translateX(${props => (props.open ? '-26px' : '100%')});
  z-index: 100;
  top: 86px;
  width: 100%;
  transition: transform ease-in-out 0.2s;

  @media screen and (min-width: 1024px) {
    position: relative;
    right: 0;
    top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: transparent;
    border-radius: 0;
    padding: 0 16px;
    gap: 0;
    transform: translateX(0);
  }
`

const MenuItem = styled.li`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.color.black};

  &:first-child {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &:nth-child(2) {
    @media screen and (min-width: 1024px) {
      span {
        display: none;
      }
    }
  }

  span {
    color: ${props => props.theme.color.blue};
    @media screen and (min-width: 1024px) {
      padding: 0 8px;
    }
    @media screen and (min-width: 1280px) {
      padding: 0 16px;
    }
  }
`

const Link = styled(NavLink)<NavLinkProps & { active?: boolean }>`
  text-decoration: none;
  border-radius: 8px;
  transition: background-color ease-in-out 0.2s;
  color: inherit;
  padding: 4px 0;

  @media screen and (min-width: 1024px) {
    padding: 4px 16px;
    background-color: ${props =>
      props.active ? props.theme.color.blue : 'transparent'};
    color: ${props =>
      props.active ? props.theme.color.white : props.theme.color.black};
    &:hover {
      background-color: ${props =>
        props.active ? props.theme.color.blue : props.theme.color.white};
    }
    &:active,
    &:focus {
      background-color: ${props => props.theme.color.blue};
      color: ${props => props.theme.color.white};
      outline: none;
    }
  }
`

const MenuIconWrapper = styled.div`
  position: absolute;
  right: 26px;
  top: 38px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const Styled = { Menu, MenuItem, MenuIconWrapper, Link }
