import styled from 'styled-components'

export const Header = styled.header<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 23px;
  overflow: hidden;
  height: 39px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Styled = { Header }
