import styled from 'styled-components'

const Logo = styled.span`
  font-size: 32px;
  line-height: 39px;
  font-weight: 400;
  color: ${props => props.theme.color.black};
  white-space: nowrap;

  span {
    color: ${props => props.theme.color.blue};
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const Styled = { Logo }
